import styled from 'styled-components';

export const Body = styled.div`
  background: #fff;
  color: #661988;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;
export const InnerBody = styled.div`
  width: 860px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  padding-bottom: 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    height: auto;
    padding-top: 10px;
  }
`;

export const BodyContent = styled.div`
  width: 650px;
  margin: 0 auto;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px 35px 0 35px;
  }
`;

export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const ImageStyle = styled.img`
  width: 500px;
  height: auto;
  border: 0;
  vertical-align: top;
  @media (max-width: 991px) {
    width: 100%;
    max-width: 350px;
  }
`;
